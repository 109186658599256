export { 
    uriErstellen,
    getURLSearchparam 
};

function uriErstellen(uri, suchParameter = {}) {
    const nUri = new URL(uri);
    const searchParams = new URLSearchParams();
    const spEntries = Object.entries(suchParameter);
    for (const [name, value] of spEntries) {
        searchParams.append(name, value);
    }
    nUri.search = searchParams.toString();
    return nUri;
}

function getURLSearchparam(name) {
    const searchparams = new URLSearchParams(window.location.search);
    if (searchparams.size === 0) {
        return null;
    }
    return searchparams.get(name);
}
