// https://github.com/google/data-layer-helper
// Ist über den asset-serivce (WDD) eingebunden

export { 
    createDatalayerHelper,
    getDatalayerValue
}

function createDatalayerHelper() {
    if (!window.newDataLayer || !window.DataLayerHelper) {
        console.debug(`[SuchOCS] DatalayerHelper: newDataLayer or DataLayerHelper not found!`);
        return undefined;
    };
    return new DataLayerHelper(window.newDataLayer);
}

function getDatalayerValue(key) {
    const suchocsDatalayerHelper = createDatalayerHelper();
    if (!suchocsDatalayerHelper) return undefined;
    return suchocsDatalayerHelper.get(key);
}
