import {getDatalayerValue, getURLSearchparam} from "../../assets/js/utils";

class DlSearchRedirect extends HTMLElement {
    constructor() {
        super();
        this.loadHandler = this.render.bind(this);
        window.addEventListener("load", this.loadHandler);
    }

    get template() {
        const template = this.querySelector("TEMPLATE");
        if (template) {
            return template.content.cloneNode(true);
        }
        return null;
    }

    get seitentyp() {
        return getDatalayerValue("seitentyp");
    }

    get suchbegriff() {
        return getDatalayerValue("suchbegriff");
    }

    get suchbegriffRedirect() {
        const suchbegriffRedirect = getURLSearchparam("suche_redirect");
        if (!suchbegriffRedirect) {
            return false;
        }
        return suchbegriffRedirect;
    }

    render() {
        if (this.suchbegriffRedirect) {
            const dlEvent = this.createDlEvent();
            this.appendChild(dlEvent);
            this.removeTemplate();
        }
    }

    createDlEvent() {
        const dlEvent = this.template.querySelector("DL-EVENT");
        if (this.seitentyp === "suche") {
            dlEvent.setAttribute("search_term", this.suchbegriff);
            dlEvent.setAttribute("search_term_origin", this.suchbegriffRedirect);
        } else {
            dlEvent.setAttribute("search_term", this.suchbegriffRedirect);
        }
        return dlEvent;
    }

    removeTemplate() {
        if (this.template.parentNode) {
            this.template.parentNode.removeChild(this.template);
        }
    }
}

if (!customElements.get("dl-search-redirect")) {
    customElements.define("dl-search-redirect", DlSearchRedirect);
}
